var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"4"}},[_c('b-media',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{ref:"previewEl",attrs:{"src":"/img/13-small.png","text":_vm.avatarText(_vm.staff.fullName),"size":"90px","rounded":""}})]},proxy:true}])},[_c('h4',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.staff.fullName || '_')+" ")]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-dark","to":{ name: _vm.constRouter.USER_STAFF.name }}},[_vm._v(" "+_vm._s(_vm.$t('Back'))+" ")]),(
                _vm.$can(
                  _vm.permissions.USER.PUT_v1_users__user_id.action,
                  _vm.permissions.USER.PUT_v1_users__user_id.resource
                )
              )?_c('b-button',{attrs:{"variant":"primary","to":{
                name: _vm.constRouter.USER_STAFF_EDIT.name,
                params: { id: _vm.$route.params.id },
              }}},[_vm._v(" "+_vm._s(_vm.$t('Edit'))+" ")]):_vm._e()],1)])],1),_c('b-col',{attrs:{"cols":"12","xl":"4"}},[_c('table',{staticClass:"mt-2 mt-xl-0 w-100"},[_c('tr',[_c('th',{staticClass:"pb-50"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"UserIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('Username')))])],1),_c('td',{staticClass:"pb-50"},[_vm._v(" "+_vm._s(_vm.staff.username || '_')+" ")])]),_c('tr',[_c('th',{staticClass:"pb-50"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"MailIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('Email')))])],1),_c('td',{staticClass:"pb-50"},[_vm._v(" "+_vm._s(_vm.staff.email || '_')+" ")])]),_c('tr',[_c('th',{staticClass:"pb-50"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"UsersIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Đội")])],1),_c('td',{staticClass:"pb-50"},[_vm._v(" "+_vm._s(_vm.staff.teamName || '_')+" ")])]),_c('tr',[_c('th',[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('Active')))])],1),_c('td',[_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"value":1,"unchecked-value":0,"switch":"","disabled":""},model:{value:(_vm.staff.isActive),callback:function ($$v) {_vm.$set(_vm.staff, "isActive", $$v)},expression:"staff.isActive"}})],1)])])])],1),_c('hr'),_c('div',[_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('validation-observer',{ref:"formRole"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleUpdateRoles.apply(null, arguments)}}},[_c('h4',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Role')))]),_c('div',{staticClass:"d-flex mb-1 px-2"},[_c('div',{staticClass:"d-flex flex-column w-100"},[_c('validation-provider',{staticClass:"d-block w-100",attrs:{"rules":"required","name":"roles"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"multiple":"","label":"name","reduce":function (item) { return item.id; },"options":_vm.roles,"placeholder":"Thêm vai trò"},model:{value:(_vm.staff.roles),callback:function ($$v) {_vm.$set(_vm.staff, "roles", $$v)},expression:"staff.roles"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"ml-1"},[_c('b-overlay',{attrs:{"show":_vm.roleLoading,"block":"","opacity":"0.6","spinner-variant":"primary"}},[_c('b-button',{staticClass:"text-nowrap",attrs:{"type":"submit","variant":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Update')))])])],1)],1)])])],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('h4',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Permission')))]),_c('permission-list',{attrs:{"per-check":_vm.staff.permissions,"loading":_vm.permissionLoading},on:{"update-permission":_vm.updatePermission}})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }