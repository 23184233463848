<template>
  <b-card>
    <div>
      <b-row>
        <b-col cols="12" xl="4">
          <!-- Media -->
          <b-media class="mb-2">
            <template #aside>
              <b-avatar
                ref="previewEl"
                src="/img/13-small.png"
                :text="avatarText(staff.fullName)"
                size="90px"
                rounded
              />
            </template>
            <h4 class="mb-1">
              {{ staff.fullName || '_' }}
            </h4>
            <div class="d-flex flex-wrap">
              <b-button
                variant="outline-dark"
                class="mr-1"
                :to="{ name: constRouter.USER_STAFF.name }"
              >
                {{ $t('Back') }}
              </b-button>
              <b-button
                v-if="
                  $can(
                    permissions.USER.PUT_v1_users__user_id.action,
                    permissions.USER.PUT_v1_users__user_id.resource,
                  )
                "
                variant="primary"
                :to="{
                  name: constRouter.USER_STAFF_EDIT.name,
                  params: { id: $route.params.id },
                }"
              >
                {{ $t('Edit') }}
              </b-button>
            </div>
          </b-media>
        </b-col>
        <b-col cols="12" xl="4">
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon icon="UserIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t('Username') }}</span>
              </th>
              <td class="pb-50">
                {{ staff.username || '_' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="MailIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t('Email') }}</span>
              </th>
              <td class="pb-50">
                {{ staff.email || '_' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="UsersIcon" class="mr-75" />
                <span class="font-weight-bold">Đội</span>
              </th>
              <td class="pb-50">
                {{ staff.teamName || '_' }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t('Active') }}</span>
              </th>
              <td>
                <b-form-checkbox
                  v-model="staff.isActive"
                  class="custom-control-primary"
                  :value="1"
                  :unchecked-value="0"
                  switch
                  disabled
                />
              </td>
            </tr>
            <!--            <tr>-->
            <!--              <th>-->
            <!--                <feather-icon icon="UserIcon" class="mr-75" />-->
            <!--                <span class="font-weight-bold">Super Admin</span>-->
            <!--              </th>-->
            <!--              <td>-->
            <!--                <b-form-checkbox-->
            <!--                  v-model="staff.isSuperAdmin"-->
            <!--                  class="custom-control-primary"-->
            <!--                  :value="1"-->
            <!--                  :unchecked-value="0"-->
            <!--                  switch-->
            <!--                  disabled-->
            <!--                />-->
            <!--              </td>-->
            <!--            </tr>-->
          </table>
        </b-col>
      </b-row>

      <hr />

      <div>
        <b-row class="mt-2">
          <b-col>
            <validation-observer ref="formRole">
              <b-form @submit.prevent="handleUpdateRoles">
                <h4 class="mb-1">{{ $t('Role') }}</h4>
                <div class="d-flex mb-1 px-2">
                  <div class="d-flex flex-column w-100">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="roles"
                      class="d-block w-100"
                    >
                      <v-select
                        v-model="staff.roles"
                        multiple
                        label="name"
                        :reduce="(item) => item.id"
                        :options="roles"
                        placeholder="Thêm vai trò"
                        class="w-100"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>

                  <div class="ml-1">
                    <b-overlay :show="roleLoading" block opacity="0.6" spinner-variant="primary">
                      <b-button type="submit" variant="primary" class="text-nowrap">
                        <span>{{ $t('Update') }}</span>
                      </b-button>
                    </b-overlay>
                  </div>
                </div>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <h4 class="mb-1">{{ $t('Permission') }}</h4>
            <permission-list
              :per-check="staff.permissions"
              :loading="permissionLoading"
              @update-permission="updatePermission"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BMedia,
  BOverlay,
  BRow,
} from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import { ref } from '@vue/composition-api';
import constRouter from '@/constants/constRouter';
import permissions from '@/constants/permissions';
import IconGender from '@core/components/icons/IconGender.vue';
import { show, updatePermissions, updateRoles } from '@/apis/apiStaff';
import PermissionList from '@/views/system/permission/permission-list/PermissionList.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import vSelect from 'vue-select';
import { roleList } from '@/apis/apiRole_Permission';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from '@validations';

export default {
  components: {
    BOverlay,
    BForm,
    PermissionList,
    IconGender,
    BButton,
    BFormCheckbox,
    BRow,
    BCol,
    BCard,
    BMedia,
    BAvatar,

    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const genderOptions = [
      { text: 'Nam', value: 'male' },
      { text: 'Nữ', value: 'female' },
    ];

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    return {
      avatarText,
      genderOptions,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      constRouter,
      permissions,
    };
  },
  data: () => ({
    required,
    staff: {
      username: '',
      password: '',
      fullName: '',
      phoneNumber: '',
      isSuperAdmin: '',
      isActive: '',
      birthDay: '',
      email: '',
      gender: '',
      facebook: '',
      address: '',
      teamName: '',
      roles: [],
      permissions: [],
    },
    id: '',
    roles: [],
    roleLoading: false,
    permissionLoading: false,
  }),
  created() {
    this.id = this.$route.params.id;
    this.fetchUser(this.id);
    this.fetchRoles();
  },
  methods: {
    fetchUser(id) {
      show(id)
        .then((res) => {
          const { data } = res.data;
          if (data) {
            this.staff.username = data.username;
            this.staff.password = '';
            this.staff.fullName = data.name;
            // this.staff.phoneNumber = data.phoneNumber;
            this.staff.isSuperAdmin = data.is_superadmin;
            this.staff.isActive = data.status;
            // this.staff.birthDay = data.birthDay;
            this.staff.email = data.email;
            this.staff.teamName = data.team?.name;
            // this.staff.gender = data.gender;
            // this.staff.facebook = data.facebook;
            // this.staff.address = data.address;
            this.staff.roles = data.roles;

            // change format for prop component
            this.staff.permissions = data.direct_permissions.map((permission) => ({
              name: permission.name,
              group: permission.group,
              limited: permission.pivot?.scope,
              permissionId: permission.id,
              id: permission.id,
            }));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchRoles() {
      roleList()
        .then(async (res) => {
          this.roles = res.data.data;
        })
        .catch((err) => console.log(err));
    },
    handleUpdateRoles() {
      const form = this.$refs.formRole;
      form.validate().then((success) => {
        if (success) {
          this.roleLoading = true;

          const { id } = this;
          const { roles } = this.staff;

          updateRoles(id, roles)
            .then(() => {
              this.roleLoading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('Update role'),
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: this.$t('Update role success!'),
                },
              });
            })
            .catch(() => {
              this.roleLoading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('Update role'),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: this.$t('Update role failed!'),
                },
              });
            });
        }
      });
    },
    updatePermission(data) {
      this.permissionLoading = true;
      console.log(data.permissions);
      updatePermissions(this.id, data.permissions)
        .then(() => {
          this.permissionLoading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Update permission'),
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: this.$t('Update permission success!'),
            },
          });
        })
        .catch((err) => {
          this.permissionLoading = false;

          if (err.response?.status === 422) {
            const { errors } = err.response.data;

            if (errors) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('Update permission'),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text:
                    errors.permissions && errors.permissions[0]
                      ? errors.permissions[0]
                      : this.$t('Invalid data'),
                },
              });
            }
          }
        });
    },
  },
};
</script>
